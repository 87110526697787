import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

interface ProgressBar {
    className?: string;
    count: number;
    current: number;
    onClickItem: (index: number) => void;
}

const ProgressDots: React.FC<ProgressBar> = (props) => {
    const { className, count, current, onClickItem } = props;

    let dots = [];
    for (let i = 0; i < count; i++) {
        dots.push(
            <DotStyled
                key={i}
                active={i === current}
                onClick={() => {
                    onClickItem(i);
                }}
            />
        );
    }

    return <ProgressDotsStyled className={className}>{dots}</ProgressDotsStyled>;
};

const DotStyled = styled.button<{ active: boolean }>`
    margin: 0 ${rem(4)};
    width: ${rem(8)};
    height: ${rem(8)};
    border-radius: ${rem(4)};
    background-color: ${({ theme, active }) =>
        active ? theme.palette.White : theme.palette.MedGrey4};
    transition: background-color 200ms ease, transform 100ms ease;
    cursor: pointer;

    ${({ active }) =>
        !active &&
        css`
            @media (hover: hover) {
                &:hover {
                    transform: scale(1.3);
                    background-color: ${({ theme }) => theme.palette.MedGrey3};
                }
            }
        `}

    ${({ active }) =>
        active &&
        css`
            width: ${rem(16)};
        `}
`;

const ProgressDotsStyled = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
`;

export default ProgressDots;
